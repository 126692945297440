import { request } from "@/api";
import type { BlogItem, BlogItemList } from "@/shared/types";

interface BlogParams {
  limit?: number;
  offset?: number;
  tag?: string[];
  query?: string;
}

export const fetchPosts = async (paramsPassed?: BlogParams) => {
  const params = {
    limit: 30,
    offset: 0,
    ...paramsPassed,
  };
  return request<BlogItemList[]>(`/blog`, { params });
};

export const fetchPostByCode = async (code: string) => {
  return request<BlogItem>(`/blog/${code}`);
};

export const deletePost = async (articleId: number) => {
  return request<BlogItem>(`/blog/${articleId}`, {
    method: "DELETE",
  });
};

export const savePost = async (data: BlogItem) => {
  return request<BlogItem>(`/blog/${data.articleId}`, {
    method: "POST",
    body: data,
  });
};
export const createPost = async (data: BlogItem) => {
  return request<BlogItem>("/blog", {
    method: "POST",
    body: data,
  });
};

export const preloadBlogTags = async () => {
  return request<
    {
      value: string;
      label: string;
    }[]
  >("/blog/tags");
};

export const uploadImage = async (base64: string) => {
  return request<{
    code: number;
    message: string;
    picture: string;
  }>("/blog/upload", {
    method: "POST",
    body: {
      pic: base64,
    },
  });
};
